import { useEffect } from 'react'
import {
	OAuthProvider,
	getRedirectResult,
	signInWithRedirect,
} from 'firebase/auth'
import { auth } from './services/firebase'
import './App.css'

function App() {
	useEffect(() => {
		const provider = new OAuthProvider('microsoft.com')
		provider.setCustomParameters({
			prompt: 'consent',
			tenant: process.env.REACT_APP_TENANT_ID || '',
		})

		getRedirectResult(auth)
			.then((result) => {
				if (result) {
					;(window as any).ReactNativeWebView.postMessage(
						JSON.stringify(result),
					)
				} else {
					signInWithRedirect(auth, provider).catch(() =>
						(window as any).ReactNativeWebView.postMessage(
							JSON.stringify({ error: true }),
						),
					)
				}
			})
			.catch(() =>
				(window as any).ReactNativeWebView.postMessage(
					JSON.stringify({ error: true }),
				),
			)
	}, [])

	return <div className="App" />
}

export default App
